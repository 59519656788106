<template>
  <b-overlay :show="loading">
    <b-card>
      <b-form>
        <b-form-checkbox
          v-model="admin"
          class="custom-control-primary mb-1"
          name="admin"
          switch
          inline
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          {{ $t('userForm.isAdminLabel') }}
        </b-form-checkbox>
        <b-row>
          <b-col md="6">
            <b-form-group
              :label="$t('userForm.nameLabel')"
              label-for="name"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="name"
                  v-model="name"
                  name="name"
                  type="text"
                  :placeholder="$t('userForm.namePlaceholder')"
                />
              </b-input-group>
              <small class="text-danger">{{ nameErrorMsg }}</small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label-for="email"
              :label="$t('userForm.emailLabel')"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="email"
                  v-model="email"
                  type="email"
                  name="email"
                  :placeholder="$t('userForm.emailPlaceholder')"
                />
              </b-input-group>
              <small class="text-danger">{{ emailErrorMsg }}</small>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label-for="password"
              :label="$t('userForm.passwordLabel')"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="password"
                  v-model="password"
                  :type="passwordFieldTypeNew"
                  name="password"
                  :placeholder="$t('userForm.passwordPlaceholder')"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconNew"
                    class="cursor-pointer"
                    @click="togglePasswordNew"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label-for="confirm-password"
              :label="$t('userForm.confirmPasswordLabel')"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="confirm-password"
                  v-model="confirmPassword"
                  :type="passwordFieldTypeRetype"
                  name="confirmPassword"
                  :placeholder="$t('userForm.confirmPasswordPlaceholder')"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconRetype"
                    class="cursor-pointer"
                    @click="togglePasswordRetype"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <small class="text-danger">{{ passwordErrorMsg }}</small>
          </b-col>

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              :disabled="!saveOk"
              @click="save"
            >
              {{ $t('userForm.save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mt-1"
              @click="cancel"
            >
              {{ $t('userForm.cancel') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BOverlay, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BOverlay,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      name: '',
      password: '',
      confirmPassword: '',
      email: '',
      admin: false,
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    validateName() {
      return this.name
    },
    validateEmail() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(this.email).toLowerCase())
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordErrorMsg() { return this.passwordOk ? '' : this.$t('userForm.passwordError') },
    nameErrorMsg() { return this.validateName ? '' : this.$t('userForm.nameError') },
    emailErrorMsg() { return this.validateEmail ? '' : this.$t('userForm.emailError') },
    passwordOk() {
      return this.password.length >= 6 && this.password === this.confirmPassword
    },
    saveOk() { return this.passwordOk && this.validateName && this.validateEmail },
  },
  methods: {
    cancel() { this.$router.push({ name: 'pages-user' }) },
    async save() {
      this.loading = true
      try {
        const payload = {
          name: this.name,
          email: this.email,
          admin: this.admin,
          password: this.password,
        }
        await this.$store.dispatch('adminUser/create', payload)
        this.$toast({
          position: 'top-right',
          component: ToastificationContent,
          props: this.$t('userForm.methods.save.then.props'),
        })
        this.email = null
        this.name = null
        this.admin = false
        this.password = ''
        this.confirmPassword = null
        this.cancel()
      } catch (e) {
        this.$toast({
          position: 'top-right',
          component: ToastificationContent,
          props: this.$t('userForm.methods.save.catch.props'),
        })
      } finally {
        this.loading = false
      }
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
  },
}
</script>
